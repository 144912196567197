
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
                <v-layout row wrap px-4 mb-4>
                    <v-flex xs9>
                        <v-breadcrumbs class="px-0" :items="$store.getters.language.data.incomes.list_path">
                            <template v-slot:item="{item}">
                                <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                    {{ item.title }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-flex>
                    <v-flex xs3></v-flex>
                </v-layout>
                <form @submit.prevent="addIncomes" autocomplete="off">
                    <v-layout row wrap>
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-text-field v-model="incomes.income_date" type="date" :label="$store.getters.language.data.incomes.income_date" dense
                                class="mx-1" @change="readIncomes()" filled outlined required>
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="accounts" v-model="incomes.account_id" dense  filled outlined item-text="account_name"
                                    item-value="account_id" :return-object="false" :label="$store.getters.language.data.incomes.account_name">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-text-field v-model="incomes.income_amount" type="text" :label="$store.getters.language.data.incomes.income_amount" dense
                                class="mx-1" filled outlined required :prefix="$store.getters.store.store_payment == 'usd' ? '$' : ''">
                            </v-text-field>
                        </v-flex>
                    
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-select class="mx-1" clearable  :items="money_move_types" v-model="incomes.money_move_type_id" dense  filled outlined item-text="money_move_type_name"
                                item-value="money_move_type_id" :return-object="false" :label="$store.getters.language.data.incomes.money_move_type_name">
                            </v-select>
                        </v-flex>

                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-select class="mx-1" clearable  :items="users" v-model="incomes.user_id" dense  filled outlined item-text="user_name"
                                item-value="user_id" :return-object="false" :label="$store.getters.language.data.incomes.user_name" :disabled="$store.getters.user.user_role != 'admin'">
                            </v-select>
                        </v-flex>

                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-textarea rows="1" auto-grow v-model="incomes.income_note" type="text" :label="$store.getters.language.data.incomes.income_note" dense
                                class="mx-1"  filled outlined>
                            </v-textarea>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit" :disabled="buttonDisable">{{$store.getters.language.data.incomes.add_btn}}</v-btn>
                        </v-flex>

                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card>
            <v-card-text>
                <v-data-table :headers="headers"   v-model="selected_rows"    :search="search" :items="rows" class="elevation-0"
                    item-key="income_id">
                    <template v-slot:[`item.income_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/incomes-list/'+item.income_id"  color="teal" class="mx-1">
                            <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-btn color="error" icon class="mx-1" @click="selectIncomes(item)" >
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <template v-slot:[`item.income_amount`]="{ item }">
                        <div>
                            {{ (item.income_amount).toLocaleString() }}
                        </div>
                    </template>
                    <template v-slot:[`item.income_date`]="{ item }">
                        <div>
                            {{ new Date(item.income_date).toISOString().split('T')[0] }}
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            
            <v-card-actions v-if="selected_rows.length > 0">
                <v-btn color="error" @click="deleteIncomesList">{{$store.getters.language.data.incomes.delete_list_btn}}</v-btn>
            </v-card-actions>
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>
<v-dialog v-model="delete_dialog" persistent max-width="400">
    <v-card>
        <v-card-title>
            {{$store.getters.language.data.incomes.delete_question}}
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="elevation-0" @click="delete_dialog = false">
                {{$store.getters.language.data.incomes.cancel_btn}}
            </v-btn>
            <v-btn color="error" class="elevation-0" @click="deleteIncomes(selected_incomes.income_id)">
                {{$store.getters.language.data.incomes.yes_btn}}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/incomes.request.js'
    export default {
        data() {
            return {
                incomes: {
                    income_date: new Date().toISOString().split('T')[0]
                },
                search: '',
                loading : true,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows:[],
                selected_incomes : {},
                delete_dialog: false,
                headers: [
                    { 
                        text: this.$store.getters.language.data.incomes.account_name,
                        align: 'start',
                        sortable: true,
                        value: 'account_name',
                    },
                    { 
                        text: this.$store.getters.language.data.incomes.user_name,
                        align: 'start',
                        sortable: true,
                        value: 'user_name',
                    },
                    { 
                        text: this.$store.getters.language.data.incomes.income_amount,
                        align: 'start',
                        sortable: true,
                        value: 'income_amount',
                    },
                    { 
                        text: this.$store.getters.language.data.incomes.income_date,
                        align: 'start',
                        sortable: true,
                        value: 'income_date',
                    },
                    { 
                        text: this.$store.getters.language.data.incomes.money_move_type_name,
                        align: 'start',
                        sortable: true,
                        value: 'money_move_type_name',
                    },   
                    { 
                        text: this.$store.getters.language.data.incomes.income_note,
                        align: 'start',
                        sortable: true,
                        value: 'income_note',
                    },   
                    {
                        text: this.$store.getters.language.data.actions,
                        align: 'start',
                        sortable: true,
                        value: 'income_id',
                    }
                ],
            }
        },
        computed: {
            accounts(){
                return this.$store.getters.accounts_list
            },
            users(){
                return this.$store.getters.users_list
            },
            money_move_types(){
                return this.$store.getters.money_move_types_list.filter(obj => obj.money_move_type == 'in')
            },
            buttonDisable() {
                return !this.incomes.income_date ||
                    !this.incomes.account_id ||
                    !this.incomes.income_amount ||
                    !this.incomes.money_move_type_id ||
                    !this.incomes.user_id
            }
        },
        mounted(){
            this.readIncomes();
            this.incomes.user_id = this.$store.getters.auth.user_id;
            this.incomes.account_id = this.accounts.find(obj => obj.account_is_default == 'true').account_id;
        },
        methods: {
            addIncomes() {
                this.loading_btn = true
                this.incomes.user_id = this.$store.getters.auth.user_id;
                requests.createIncomes(this.incomes).then(r => {
                    if (r.status == 200) {
                        this.incomes = {
                            income_date: new Date().toISOString().split('T')[0]
                        }
                        this.rows.push(r.data.new_data)
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.add_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteIncomes(income_id) {
                requests.deleteIncomes(income_id).then(r => {
                    this.delete_dialog = false
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{
                            return f.income_id != income_id
                        })
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.delete_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
            },
            
            deleteIncomesList() {
                let ids = this.selected_rows.map(m => m.income_id)
                requests.deleteIncomesList(ids).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{  
                            return ids.indexOf(f.income_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.delete_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
            },
            
            readIncomes() {
                this.loading = true
                requests.reportIncomes(`store_id=${this.$store.getters.store.store_id}&income_date=${this.incomes.income_date}`).then(r => {
                    if (r.status == 200) {
                        this.rows = r.data
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            selectIncomes(i) {
                this.selected_incomes = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    